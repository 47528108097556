@mixin contentWidth($base-width) {
  .jx_content_width {
    width: $base-width;
  }
}
@media screen and (max-width: 1300px) {
  @include contentWidth($base-width: 1100px);
}
@media screen and (min-width: 1300px) {
  @include contentWidth($base-width: 1280px);
}

@mixin contentHeight($base-height) {
  .jx_content_height {
    height: $base-height;
  }
}
@media screen and (max-width: 1300px) {
  @include contentHeight($base-height: 550px);
}
@media screen and (min-width: 1300px) {
  @include contentHeight($base-height: 720px);
}

@mixin contentSquare($base-height) {
  .jx_content_square {
    width: $base-height;
    height: $base-height;
  }
}
@media screen and (max-width: 1300px) {
  @include contentSquare($base-height: 550px);
}
@media screen and (min-width: 1300px) {
  @include contentSquare($base-height: 720px);
}


// 尺寸
@mixin iphone () {
  @media screen and (max-width: 414px) {
    @content;
  }
}
@mixin ipad () {
  @media screen and (max-width: 1100px) {
    @content;
  }
}
@mixin mac () {
  @media screen and (max-width: 1300px) {
    @content;
  }
}
@mixin imac () {
  @media screen and (min-width: 1300px) {
    @content;
  }
}