$official-width: 1000px;
$official-width-sm: 100%;

// The Color of O2Team Brand
$color-brand: #1A84F8;
$color-brand-light: #78A4F4;
$color-brand-dark: #346FC2;

// Color
$color-success: #0BB353;
$color-error: #F05858;
$color-warning: #F39C15;

// Color Palette
$color-black-0: #000;
$color-black-1: #333;
$color-black-2: #7F7F7F;
$color-black-3: #B2B2B2;

$color-grey-0: #333;
$color-grey-1: #1A283D;
$color-grey-2: #394C66;
$color-grey-3: #6C7D95;
$color-grey-4: #A7B6C9;
$color-grey-5: #E9ECF0;
$color-grey-6: #F7F8F9;
$color-white: #FFF;

$color-border: #E9ECF0;

// Text Color
$color-text-base: #000; // 文字的基本色
$color-text-base-inverse: #FFF; // 反色
$color-text-secondary: #36D57D; // 辅助色
$color-text-placeholder: #C9C9C9;
$color-text-disabled: #A9B4C4;

// Link
$color-link: #2E58D6;
// $color-link-hover: #79A1EB;
// $color-link-active: #4F7DE2;
// $color-link-disabled: #BFBFBF;

// 背景色
$color-bg: #FFF;
$color-bg-base: #F7F8F9;

// font-weight
$font-weight-sm: 200;
$font-weight-base: 400;
$font-weight-lg: 500;

// font-size
$font-size-xs: 10px; // 非常用字号，用于标签
$font-size-sm: 12px; // 用于辅助信息
$font-size-base: 14px; // 常用字号
$font-size-lg: 16px; // 常规标题
$font-size-xl: 18px; // 大标题
$font-size-xxl: 20px; // 用于大号的数字
$font-size-xxxl: 24px; // 用于大号的数字
$font-size-xxxxl: 38px; // 用于大号的数字
$font-size-title: 38px; // 用于大号的数字

// Border Radius
$border-radius-sm: 2px;
$border-radius-md: 4px;
$border-radius-lg: 6px;
$border-radius-xl: 8px;
$border-radius-xxl: 16px;
$border-radius-circle: 50%;