// AnimatedSwitch.less

// 很多动画都需要给路由对应组件最外层元素设置position: absolute;
.route {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

// 帧动画
.fade-enter {
  opacity: 0;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}